import { useState, useEffect } from 'react'
import './App.scss'
import Cookies from 'js-cookie';

import { getBooks } from 'api/book'
import styled from "styled-components"

const token = Cookies.get('csrf_token');

const Container = styled('div')`
  flex-wrap: wrap;
  gap: 32px;
  display: flex;
`

const Book = styled('div')`
  text-align: center;
  width: 200px;
  border: 1px solid #000;
  padding: 8px;

  a {
    text-decoration: none;
  }

  .Image {
    position: relative;
    padding-top: 150%;

    img {
      top: 0;
      left: 0;
      position: absolute;
      width: 100%;
      // object-fit: cover
    }
  }

  .Title {

  }
`

const App = (props) => {
  const [books, setBooks] = useState([])

  useEffect(async () => {
    const books = await getBooks()

    setBooks(books)
  }, [])

  return token && (
    <Container>
      {
        books.map(({ attributes: { title, file_url, cover_url }}) => {
          return (
            <Book>
              <a href={file_url}>
                <div class="Image">
                  <img src={cover_url}/>
                </div>
                <div class="Title">
                  {title}
                </div>
              </a>
            </Book>
          )
        })
      }
    </Container>
  )
}

export default App
